import { isMobile } from 'Util/util';

/**
 * Initializes collapsible specifications.
 *
 * @param {HTMLElement} [wrapper] - The wrapper element containing collapsible specs controls.
 * @private
 */
export default function initCollapsibleSpecs(wrapper) {
    const collapseControls = wrapper ? [...wrapper.querySelectorAll('.js-collapsible-specs-control')] : [...document.querySelectorAll('.js-collapsible-specs-control')];

    collapseControls.forEach((control) => {
        const toggleState = () => {
            // Toggle aria-expanded attribute
            const isExpanded = control.getAttribute('aria-expanded') === 'true';
            control.setAttribute('aria-expanded', (!isExpanded).toString());

            control.classList.toggle('collapsible-specs-control-closed');
            // If the class js-collapsible-specs-independent present each control toggles one item
            // In opposite on the cart page one controll toggles all the product items
            if (control.classList.contains('js-collapsible-specs-independent')) {
                control.closest('.js-collapsible-specs')?.classList.toggle('collapsible-specs-collapsed');
            } else {
                const specs = [...(control.closest('.card')?.querySelectorAll('.js-collapsible-specs') || [])];
                specs.forEach((item) => {
                    item.classList.toggle('collapsible-specs-collapsed');
                });
            }
        };
        const tapArea = control.closest('.js-collapsible-specs')?.querySelector('.js-collapsible-specs-tap-area');
        if (tapArea) {
            tapArea.addEventListener('click', toggleState);
        } else {
            control.addEventListener('click', toggleState);
        }
        // Collapse open item on mobile if indicated by the class
        if (isMobile() && control.classList.contains('js-collapsible-specs-collapse-mobile') && control.getAttribute('aria-expanded') === 'true') {
            toggleState();
        }
    });
}
