'use strict';

import { Datepicker } from 'vanillajs-datepicker';
import NL from 'vanillajs-datepicker/locales/nl';
import moment from 'moment';

import VZDateField from './VZDateField';

Object.assign(Datepicker.locales, NL);

/**
 * @class VZDatePicker
 * @param {HTMLInputElement} element - The element to create an instance on
 *
 * @example
 * <div class="form-group calendar calendar-icon overflow-visible mb-1 mb-lg-3">
 *      <input
 *          is="vz-datepicker"
 *          type="text"
 *          id="{ID}"
 *          class="form-control"
 *          autocomplete="off"
 *          required
 *          aria-required="true"
 *          placeholder="DD-MM-JJJJ"
 *          data-available-dates="1984-02-29,1984-03-01"
 *          data-max-date="yyyy/MM/dd"
 *          data-min-date="yyyy/MM/dd"
 *          data-maxdate-error="Max date error"
 *          data-mindate-error="Min date error"
 *          data-missing-error="Missing error"
 *          data-invalid-error="Invalid error"
 *          data-pattern-error="Pattern error"
 *      />
 *      <label class="form-control-label" for="{ID}">
 *           {LABEL_DATEPICKER}
 *      </label>
 *      <div class="invalid-feedback pe-none"></div>
 *      <div class="calendar-icon pe-none"></div>
 * </div>
 *
 * <div class="calendar-w-toggle__datepicker js-datepicker">
 *      <div class="form-group custom-control custom-checkbox mb-1 mb-lg-3 calendar-w-toggle__checkbox">
 *          <div class="form-control">
 *              <input
 *                     is="vz-input"
 *                     type="checkbox"
 *                     id="{ID}-asap"
 *                     class="custom-control-input sr-only js-datepicker-asap"
 *                     value="false"
 *                     name="{NAME}"
 *               />
 *              <label class="custom-control-label" for="{ID}-asap">
 *                     {LABEL_CHECKBOX}
 *              </label>
 *          </div>
 *      </div>
 *      <div class="form-group calendar calendar-icon overflow-visible mb-1 mb-lg-3 calendar-w-toggle__calendar">
 *          {DATEPICKER_FORM_GROUP}
 *      </div>
 * </div>
 */
class VZDatePicker extends VZDateField {
    constructor() {
        super();

        this.availableDates = this.getAttribute('data-available-dates')?.split(',') || [];
        this.maxDate = this.getAttribute('data-max-date');
        this.minDate = this.getAttribute('data-min-date');
        this.minDateUnFormatted = new Date(this.minDate);
        this.maxDateUnFormatted = new Date(this.maxDate);

        this.now = Date.now();
        this.parentElement.setAttribute('id', `c${this.now}`);

        this.createDatePicker = this.createDatePicker.bind(this);
        this.datesDisabled = this.datesDisabled.bind(this);
        this.changeDate = this.changeDate.bind(this);
        this.fixUnavailableDateOnBlur = this.fixUnavailableDateOnBlur.bind(this);
        this.setDatePickerDate = this.setDatePickerDate.bind(this);
        this.setDatePickerDefault = this.setDatePickerDefault.bind(this);

        this.datepicker = this.createDatePicker();

        this.addEventListener('show', this.constructor.setView);
        this.addEventListener('changeView', this.constructor.setView);
        this.addEventListener('changeDate', this.changeDate);
        this.addEventListener('setDatePickerDate', this.setDatePickerDate);

        // Invalid date inputs are ignored, so lets listen for the blur event as well
        this.addEventListener('blur', this.fixUnavailableDateOnBlur);

        this.setFieldStatus();

        // Enable ASAP option
        this.dataAsapDateAttribute = 'data-asap-date';
        this.datePickerContainer = this.parentElement.closest('.js-datepicker-form');
        this.asapToggle = this.datePickerContainer ? this.datePickerContainer.querySelector('input.js-datepicker-asap') : null;

        if (this.asapToggle) {
            this.setDatePickerAsap = this.setDatePickerAsap.bind(this);
            this.asapToggle.addEventListener('change', this.setDatePickerAsap);
        }
    }

    /**
     * @private setFieldStatus
     * @description
     * Sets an empty class when the element is empty for styling purposes.
     */
    setFieldStatus() {
        const hasValue = this.value.length > 0;

        if (hasValue) {
            this.setAttribute('data-value', this.value);
            this.classList.remove('empty');
        } else {
            this.classList.add('empty');
        }
    }

    /**
     * @private datesDisabled
     * @param {Date} date - A date object
     * @returns {boolean} - Whether a date should be disabled
     *
     * @description
     * If availableDates is not empty, disable dates that are not in this array
     */
    datesDisabled(date) {
        // If availableDates is empty, do not disable any dates
        if (!this.availableDates || this.availableDates.length === 0) return false;

        let day = date.getDate();
        let month = date.getMonth() + 1; // Month is index based, so we need to do +1
        const year = date.getFullYear();
        day = day < 10 ? `0${day}` : day;
        month = month < 10 ? `0${month}` : month;

        // Needs to be mm-dd-yyyy for the disable functionality to work
        const dateString = `${month}-${day}-${year}`;

        return !this.availableDates.includes(dateString);
    }

    /**
     * @private createDatePicker
     * @returns {HTMLElement} - A datepicker element
     *
     * @description
     * Will instantiate a datepicker for this element
     */
    createDatePicker() {
        const options = {
            autohide: true,
            container: `#c${this.now}`,
            defaultViewDate: this.now,
            datesDisabled: this.datesDisabled,
            format: {
                toValue(date) {
                    return Datepicker.parseDate(date, 'dd-mm-yyyy', 'nl');
                },
                toDisplay(date) {
                    return Datepicker.formatDate(date, 'dd-mm-yyyy', 'nl');
                }
            },
            language: 'nl',
            maxDate: new Date(this.maxDate),
            minDate: new Date(this.minDate),
            nextArrow: '<i class="icon icon-chevron-right"></i>',
            orientation: 'bottom auto',
            prevArrow: '<i class="icon icon-chevron-left"></i>',
            updateOnBlur: true
        };

        return new Datepicker(this, options);
    }

    /**
     * @private setView
     * @param {Event} evt - Custom event fired by the datepicker
     *
     * @description
     * Sets a data-view attribute used for styling purposes
     */
    static setView(evt) {
        evt.detail.datepicker.picker.element.setAttribute('data-view', evt.detail.viewId);
    }

    /**
     * @private getFormattedDate
     * @param {Date} dateObj - A date object
     * @returns {string} formattedDate
     */
    static getFormattedDate(dateObj) {
        const day = `0${dateObj.getDate()}`.slice(-2);
        const month = `0${dateObj.getMonth() + 1}`.slice(-2);
        const year = dateObj.getFullYear();

        return `${year}-${month}-${day}`;
    }

    /**
     * @private changeDate
     * @param {Event} evt - Custom event fired by the datepicker
     *
     * @description
     * Updates the data-value attribute on the datepicker and triggers form update.
     */
    changeDate(evt) {
        if (evt && evt.detail?.date) {
            this.setAttribute('data-value', this.constructor.getFormattedDate(evt.detail.date));

            // If ASAP date was previously selected check if checkbox should be toggled
            if (this.getAttribute(this.dataAsapDateAttribute)) this.resetDatePickerAsap(evt.detail.date);
        } else {
            this.removeAttribute('data-value');
        }

        this.closest('form').dispatchEvent(new CustomEvent('change'));
        this.dispatchEvent(new CustomEvent('input'));
        this.blur();
    }

    /**
     * @private fixUnavailableDateOnBlur
     * @param {Event} evt - Blur event fired by the datepicker
     *
     * @description
     * Checks if provided date is part of availableDates and if not triggers a value change.
     */
    fixUnavailableDateOnBlur(evt) {
        const newDate = moment(evt.target.value, 'DD-MM-YYYY').format('MM-DD-YYYY');

        // If the provided date is not available, automatically set a default value that is available
        if (!this.availableDates.includes(newDate)) this.setDatePickerDefault(newDate);
    }

    /**
     * @private setDatePickerDefault
     * @param {string} date - A date string
     *
     * @description
     * Sets datepicker value to `minDateUnFormatted` (if given date is in he past) or `maxDateUnFormatted` (if given date is in the future, or invalid somehow).
     *
     */
    setDatePickerDefault(date) {
        const newDate = moment(date, 'MM-DD-YYYY').isBefore(this.minDate) ? this.minDateUnFormatted : this.maxDateUnFormatted;

        // Set new automatic value and refresh date input
        this.datepicker.setDate(newDate);

        this.dispatchEvent(new CustomEvent('change'));
    }

    /**
     * @private setDatePickerAsap
     *
     * @description
     * Sets datepicker value to `minDate` (first available) on ASAP checkbox checked.
     *
     */
    setDatePickerAsap() {
        if (this.asapToggle.checked) {
            const date = this.momentFormatter(this.minDate);

            this.setAttribute(this.dataAsapDateAttribute, date);
            this.datepicker.setDate(date);

            // Refresh date field (make sure change event didn't affect checkbox)
            this.dispatchEvent(new CustomEvent('change'));

            this.asapToggle.checked = true;
            this.asapToggle.setAttribute('value', true);
        } else {
            this.removeAttribute(this.dataAsapDateAttribute);
            this.asapToggle.setAttribute('value', false);
        }
    }

    /**
     * @private setDatePickerDate
     * @param {Event} evt - Custom event fired by other components
     *
     * @description
     * Sets datepicker value to whatever is sent by the component
     *
     */
    setDatePickerDate(evt) {
        if (evt && evt.detail?.date) {
            const asapDate = evt.detail.asap && evt.detail.asap === 'true';

            if (asapDate) this.setAttribute(this.dataAsapDateAttribute, evt.detail.date);
            this.datepicker.setDate(evt.detail.date);

            // Refresh date field
            this.dispatchEvent(new CustomEvent('change'));

            // Handle ASAP toggle
            if (asapDate) {
                this.asapToggle.checked = true;
                this.asapToggle.setAttribute('value', true);
            }
        }
    }

    /**
     * @private resetDatePickerAsap
     * @param {string} date - Selected date
     *
     * @description
     * Resets ASAP checkbox if first available date is no longer selected.
     *
     */
    resetDatePickerAsap(date) {
        if (this.getAttribute(this.dataAsapDateAttribute) !== this.momentFormatter(date)) this.asapToggle.checked = false;
    }

    /* eslint-disable class-methods-use-this */
    /**
     * @private momentFormatter
     * @param {string} date - Date to be formatted
     * @returns {string} - momentjs formatted date
     *
     * @description
     * Using momentjs to format date to a desired format for vanillajs-datepicker display
     *
     */
    momentFormatter(date) {
        return moment ? moment(date).locale('nl').format('DD-MM-YYYY') : date;
    }
    /* eslint-enable class-methods-use-this */
}

export default window.customElements.get('vz-datepicker') || VZDatePicker;
if (!window.customElements.get('vz-datepicker')) {
    window.customElements.define('vz-datepicker', VZDatePicker, { extends: 'input' });
}
