export class VZLeadFormTrigger extends HTMLElement {
    connectedCallback() {
        this.triggerBtn = this.querySelector('.js-btn-leadmodal');
        if (this.triggerBtn) {
            this.triggerBtn.addEventListener('click', this.openLeadForm.bind(this));
        }
    }

    // eslint-disable-next-line class-methods-use-this
    openLeadForm(e) {
        e.preventDefault();
        const formId = this.getAttribute('for');
        let leadForm = formId ? document.querySelector(`vz-lead-form[id="${formId}"]`) : null;

        // Fallback if no matching ID found
        if (!leadForm) {
            leadForm = document.querySelector('vz-lead-form');
        }

        if (leadForm) {
            leadForm.open();
        } else {
            console.error('No lead form found');
        }
    }
}

if (!window.customElements.get('vz-lead-form-trigger')) {
    window.customElements.define('vz-lead-form-trigger', VZLeadFormTrigger);
}
