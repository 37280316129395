'use strict';

import decorators from 'Decorators/index';
import { getFieldValidationMessage } from 'Components/form/form';

/**
 * @class VZDateField
 * @param {HTMLInputElement} element - The element to create an instance on
 *
 * @example
 * <input
 *   is="vz-datefield"
 *   type="text"
 *   data-mask="date"
 *   class="form-control"
 *   autocomplete="off"
 *   required
 *   aria-required="true"
 *   maxlength="10"
 *   placeholder="DD-MM-JJJJ"
 *   data-max-date="yyyy/MM/dd"
 *   data-min-date="yyyy/MM/dd"
 *   data-maxdate-error="Max date error"
 *   data-mindate-error="Min date error"
 *   data-missing-error="Missing error"
 *   data-invalid-error="Invalid error"
 * />
 */
class VZDateField extends HTMLInputElement {
    constructor() {
        super();

        const minDate = this.getAttribute('data-min-date');
        const maxDate = this.getAttribute('data-max-date');

        this.minDate = minDate !== null ? new Date(minDate) : null;
        this.maxDate = maxDate !== null ? new Date(maxDate) : null;

        this.validationOverrideValue = false;

        this.setFieldStatus = this.setFieldStatus.bind(this);

        this.addEventListener('input', this.setFieldStatus);
        this.addEventListener('change', this.setFieldStatus);

        this.addEventListener('change', this.validateDate);
        this.addEventListener('change', this.validateField);

        this.addEventListener('blur', this.validateDate);
        this.addEventListener('blur', this.validateField);

        decorators.valid(this, this);

        if (this.hasAttribute('data-mask')) {
            decorators.mask(this, this);
        }

        // Set initial state
        this.setFieldStatus();
    }

    /**
     * @private setFieldStatus
     * @description
     * Sets an empty class when the element is empty for styling purposes.
     */
    setFieldStatus() {
        const hasValue = this.value.length > 0;
        if (hasValue) {
            this.classList.remove('empty');
        } else {
            this.classList.add('empty');
        }
    }

    /**
     * @private validateDate
     * @param {Event} evt - On Blur even
     */
    validateDate(evt) {
        if (evt.target.value.length === 0) return;

        const element = evt.target;
        const elValue = element.dataset.value ? element.dataset.value : element.value;
        const [day, month, year] = elValue.split('-');
        const dateValue = `${month}/${day}/${year}`;
        const chosenDate = new Date(dateValue);

        /**
         * BO-3100 & BO-3316:
         * Base date field validation breaks appointment picker date fields.
         * We are using vanillajs-datepicker there so we will rely on it instead for min/max or unavailable dates.
         */
        element.dataset.invalid = false;
        element.dataset.invalidMax = this.validationOverrideValue;
        element.dataset.invalidMin = this.validationOverrideValue;

        element.setCustomValidity('');

        // Invalid
        if (window.isNaN(chosenDate) || elValue.length < 10) {
            element.dataset.invalid = true;

            element.setCustomValidity('invalid');
        }
    }

    /**
     * @private validateField
     * @param {Event} evt - blur or invalid event
     * @param {string} [msg] - validation message
     */
    validateField(evt, msg) {
        const message = msg || getFieldValidationMessage(evt.target);

        if (message) {
            const element =
                this.parentElement.querySelector('.error-msg') ||
                (function createErrorElement() {
                    const el = document.createElement('small');

                    el.classList.add('error-msg', 'text-primary');

                    return el;
                })();

            element.innerText = message;

            evt.target.parentElement.insertAdjacentElement('beforeend', element);
            evt.target.classList.add('invalid');
            evt.target.classList.remove('valid');
        } else {
            const element = this.parentElement.querySelector('.error-msg');

            if (element) element.remove();

            evt.target.classList.remove('invalid');
            evt.target.classList.add('valid');
        }
    }
}

export default window.customElements.get('vz-datefield') || VZDateField;
if (!window.customElements.get('vz-datefield')) {
    window.customElements.define('vz-datefield', VZDateField, { extends: 'input' });
}
