'use strict';

/**
 * @public prepareFormValues
 * @param {HTMLElement} app - The app component
 *
 * @description
 * Sets the value of an input field as a property. By doing this, a previous
 * selection can be preselected when navigating back in the product configurator.
 */
export function prepareFormValues(app) {
    const valueTypes = ['text', 'hidden', 'range', 'email', 'number', 'tel', 'password'];
    [...app.querySelectorAll('input')].forEach((field) => {
        if (valueTypes.indexOf(field.type) !== -1) {
            field.setAttribute('value', field.value);
        } else if (field.type === 'radio' || field.type === 'checkbox') {
            if (field.checked) {
                field.setAttribute('checked', true);
            } else {
                field.removeAttribute('checked');
            }
        }
    });
}

/**
 * @public getFieldValidationMessage
 * @param {HTMLInputElement | HTMLSelectElement} field - Invalid field element
 * @returns {string | false} - Error message for invalid field
 */
export function getFieldValidationMessage(field) {
    // Valid
    if (field.validity.valid) return false;

    // Required
    if (field.required && field.value.length === 0) return field.getAttribute('data-missing-error');

    // Checked
    if (!field.checked && (field.matches('[type="checkbox"]') || field.matches('[type="radio"]'))) return field.getAttribute('data-missing-error');

    // Regex
    if (field.pattern && !new RegExp(field.pattern).test(field.value)) return field.getAttribute('data-pattern-error');

    // Dates
    if (field.dataset.mask === 'date' && field.dataset.invalid === 'true') return field.getAttribute('data-invalid-error');
    if (field.dataset.mask === 'date' && field.dataset.invalidMin === 'true') return field.getAttribute('data-mindate-error');
    if (field.dataset.mask === 'date' && field.dataset.invalidMax === 'true') return field.getAttribute('data-maxdate-error');
    if (field.dataset.mask === 'addition' && field.dataset.multipleAdditions === 'true') return field.getAttribute('data-missing-error');

    // No valid error messages
    return false;
}
